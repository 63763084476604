export default function initPopup() {
    let popups = document.querySelectorAll('[data-popup]')
    if (popups.length < 1) {
        console.warn('Не найдено ни одного элемента с атрибутом data-popup')
        return
    }

    popups.forEach(link => {
        link.addEventListener('click', (e) => {
            e.preventDefault()
            openPopup(link)
        })
    })

    function openPopup(link) {
        document.body.classList.add("popup-opened")
        let popup = document.querySelector(link.getAttribute('data-popup'))
        popup.classList.add('opening')
        setTimeout(() => {
            popup.classList.remove('opening')
            popup.classList.add('opened')
        }, 50)


        popup.addEventListener('click', (e) => {
            if (e.target.classList.contains('popup') || e.target.classList.contains('popup__close')) {
                closePopup(popup)
            }
        })
    }

    function closePopup(popup) {
        popup.classList.add('closing')
        setTimeout(() => {
            popup.classList.remove('closing')
            popup.classList.remove('opened')
            document.body.classList.remove("popup-opened")
        }, 500)

        popup.removeEventListener('click', closePopup)

    }
}