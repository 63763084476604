import Swiper, { Navigation } from "swiper"
Swiper.use([Navigation])
import initPopup from './popup.js'
import initTabs from "./tabs";

let app = new Vue({
    el: "#app",
    data() {
        return {
            loader: false,
            isDesktop: true,
            currentDistrict: 'Не выбран',
            selectedButton: '',
            type: "OUTSIDE",
            screen: "MAIN",
            inside: 0,
            innerPage: false,
            innerNavigation: false,
            loaderText: 0,
            started: false,
            audioMuted: false,
            innerData: {},
            fullscreen: false,
            loadedData: [],
            currentItem: 0,
            images: [[], []],
            points: [],
            depth: 0,
            imagesLoaded: false,
            construction: 'Индустриальный',
            currentType: '',
            currentTypeId: '',
            active: false,
            insidePoints: [],
            imageAnimation: true,
            windowOrientation: null,
            link: {
                full: [],
                district: '',
                type: '',
                currentType: '',
                point: ''
            }
        }
    },
    created() {
        this.setWindow()
    },
    async mounted() {
        initPopup()
        await this.fetchShowroomData()
        this.loadedData.forEach((item) => {
            this.parseImages(item)
        })
        setTimeout(() => {
            this.images[0].forEach((img) => {
                let image = new Image()
                image.src = img
            }, 500)
        })
        this.currentType = this.loadedData[this.currentItem].sections[1].sections[this.inside].name;
        this.currentTypeId =  this.loadedData[this.currentItem].sections[1].sections[this.inside].id;
        window.addEventListener("orientationchange", this.windowCurrentOrientation.bind(this))
        if (window.location.pathname !== '/') {
            this.openLink()
        }
    },
    watch: {
        screen(val) {
            if (!this.imagesLoaded) {
                this.images[1].forEach((img) => {
                    let image = new Image()
                    image.src = img
                })
                this.imagesLoaded = true
            }
        },
    },
    methods: {
        async setWindow() {
            if (window.matchMedia("(max-width: 1024px)").matches) {
                this.isDesktop = false
                this.windowCurrentOrientation()
                document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1440, minimal-ui, initial-scale=0, viewport-fit=cover')
            } else {
                this.isDesktop = true
            }
        },
        windowCurrentOrientation() {
            this.windowOrientation = window.orientation
        },
        openLink() {
            this.link.full = window.location.pathname.split('/').filter(i => i)
            if (this.link.full.length === 3) {
                [this.link.district, this.link.type, this.link.point] = this.link.full
                this.$refs[String(this.link.district)][0].click()
                this.$refs[String(this.link.point)][0].click()
            } else {
                [this.link.district, this.link.type, this.link.currentType, this.link.point] = this.link.full
                this.$refs[String(this.link.district)][0].click()
                this.$refs[String(this.link.type)].click()
                setTimeout(() => {
                    this.$refs[String(this.link.currentType)][0].click()
                }, 100)
                setTimeout(() => {
                    this.$refs[String(this.link.point)][0].click()
                }, 100)

            }
        },
        parseImages(o) {
            let entries = Object.entries(o)

            for (let [key, value] of entries) {
                if (value === "INDUSTRIAL" || value === "MONOLIT") {
                    this.depth = 0
                }
                if (value === "OUTSIDE") {
                    this.depth = 1
                }

                if (typeof value === "object") {
                    this.parseImages(value)
                } else {
                    if (typeof value === "string" && value.includes("/upload") && !value.includes("src=")) {
                        // depth позволяет сортировать картинки 1 и 2го уровня
                        this.images[this.depth].push(value)
                    }
                }
            }
        },
        getPoints(elems) {
            this.points = []
            elems.forEach((elem) => {
                this.points.push(elem)
            })
        },
        getInsidePoints(elems) {
            this.insidePoints = []
            elems.forEach((elem) => {
                elem.elements.forEach((insidePoint) => {
                    this.insidePoints.push(insidePoint)
                })
            })
        },
        setInsideScreen(index, type) {
            this.inside = index
            if (!type) {
                this.fullscreen = false
                return
            }

            if (type === "elements") {
                this.fullscreen = false
            } else {
                this.fullscreen = true
            }
        },
        createLink(itemId, categoryId) {
            let self = this
            if (self.type === "OUTSIDE") {
                window.history.pushState(null, null, `/district_${self.currentItem}/${self.type}/point_${itemId}/`)
            } else {
                window.history.pushState(null, null, `/district_${self.currentItem}/${self.type}/currentType_${categoryId}/point_${itemId}/`)
            }
        },
        removeLink() {
            window.history.pushState(null, null, '/')
        },
        async fetchShowroomData() {
            let response = await fetch("/api/getStructure/")
            let json = await response.json()
            if (json.success) {
                this.loadedData = json.data
            } else {
                console.error("При загрузке данных произошла ошибка: ", json.message)
            }
        },

        changeScreen(screen, type) {
            this.screen = screen
            if (type) {
                this.type = type
            }
        },
        changeType(type) {
            this.type = type
        },
        initSlider() {
            setTimeout(() => {
                if (this.$refs.swiper) {
                    new Swiper(this.$refs.swiper, {
                        navigation: {
                            prevEl: this.$refs.swiper.querySelector(".swiper-button-prev"),
                            nextEl: this.$refs.swiper.querySelector(".swiper-button-next"),
                        },
                        observeParents: true,
                        observer: true,
                        observeSlideChildren: true,
                    })
                }
            }, 100)
        },
        openInner(data) {
            this.innerData = data
            this.innerPage = true
            setTimeout(() => {
                this.initSlider()
                initTabs()
                initPopup()
            }, 100)
        },
        openFullText() {
          let text = document.getElementById('fullText');
          this.active = !this.active;
          text.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
          });
        },
        openPrev(index) {
            this.imageAnimation = false
            if (this.type === "OUTSIDE") {
                if (index !== 0) {
                    this.innerData.id = this.points[index - 1][0].id;
                    this.innerData.img = this.points[index - 1][0].background;
                    this.innerData.desc = this.points[index - 1][0].description;
                    this.innerData.elementIndex = this.innerData.elementIndex - 1;
                } else {
                    this.innerData.id = this.points[this.points.length - 1][0].id;
                    this.innerData.img = this.points[this.points.length - 1][0].background;
                    this.innerData.desc = this.points[this.points.length - 1][0].description;
                    this.innerData.elementIndex = this.points.length - 1;
                }
            } else {
                if (index !== 0) {
                    this.innerData.id = this.insidePoints[index - 1][0].id;
                    this.innerData.categoryId = this.insidePoints[index - 1][0].categoryId;
                    this.innerData.img = this.insidePoints[index - 1][0].background;
                    this.innerData.desc = this.insidePoints[index - 1][0].description;
                    this.innerData.pointsIndex = this.innerData.pointsIndex - 1;
                } else {
                    this.innerData.id = this.insidePoints[this.insidePoints.length - 1][0].id;
                    this.innerData.categoryId = this.insidePoints[this.insidePoints.length - 1][0].categoryId;
                    this.innerData.img = this.insidePoints[this.insidePoints.length - 1][0].background;
                    this.innerData.desc = this.insidePoints[this.insidePoints.length - 1][0].description;
                    this.innerData.pointsIndex = this.insidePoints.length - 1;
                }
            }
            initTabs()
            this.imageAnimation = true
            this.construction = 'Индустриальный';
            this.innerNavigation = true;

            if (this.active === true) {
                this.openFullText();
            }
            this.initSlider()
            this.createLink(this.innerData.id, this.innerData.categoryId)

        },
        openNext(index) {
            this.imageAnimation = false;

            if (this.type === "OUTSIDE") {
                if (index !== this.points.length - 1) {
                    this.innerData.id = this.points[index + 1][0].id;
                    this.innerData.img = this.points[index + 1][0].background;
                    this.innerData.desc = this.points[index + 1][0].description;
                    this.innerData.elementIndex = this.innerData.elementIndex + 1;
                } else {
                    this.innerData.id = this.points[0][0].id;
                    this.innerData.img = this.points[0][0].background;
                    this.innerData.desc = this.points[0][0].description;
                    this.innerData.elementIndex = 0;
                }
            } else {
                if (index !== this.insidePoints.length - 1) {
                    this.innerData.id = this.insidePoints[index + 1][0].id;
                    this.innerData.categoryId = this.insidePoints[index + 1][0].categoryId;
                    this.innerData.img = this.insidePoints[index + 1][0].background;
                    this.innerData.desc = this.insidePoints[index + 1][0].description;
                    this.innerData.pointsIndex = this.innerData.pointsIndex + 1;
                } else {
                    this.innerData.id = this.insidePoints[0][0].id;
                    this.innerData.categoryId = this.insidePoints[0][0].categoryId;
                    this.innerData.img = this.insidePoints[0][0].background;
                    this.innerData.desc = this.insidePoints[0][0].description;
                    this.innerData.pointsIndex = 0;
                }
            }

            initTabs()
            this.imageAnimation = true;
            this.construction = 'Индустриальный';

            if (this.active === true) {
                this.openFullText();
            }
            this.initSlider()
            this.createLink(this.innerData.id, this.innerData.categoryId)
        },
        loaderAnimation() {
            this.started = true
            setTimeout(() => {
                window.equal()
            }, 0)
            setTimeout(() => {
                this.$refs?.audio?.play()
                this.loaderText = -1
            }, 2000)
            setTimeout(() => {
                this.loaderText = 1
            }, 3000)
            setTimeout(() => {
                this.loaderText = 2
            }, 4500)
            setTimeout(() => {
                this.loaderText = 3
            }, 8000)
            setTimeout(() => {
                this.loaderText = 4
            }, 11500)
            setTimeout(() => {
                this.loaderText = 5
            }, 17000)
        },
        disableSound() {
            if (this.audioMuted) {
                this.$refs.audio.muted = false
            } else {
                this.$refs.audio.muted = true
            }
            this.audioMuted = !this.audioMuted
        },
    },
})
