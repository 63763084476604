// PLUGINS======================================================================================
import Inputmask from "inputmask"
// import "@fancyapps/fancybox"
// import LazyLoad from "vanilla-lazyload"

// MODULES======================================================================================
import "./js-modules/showroom"
import initEqualizer from "./js-modules/equalizer"
import initAnimations from "./js-modules/animations"

// init ========================================================================================
document.addEventListener("DOMContentLoaded", () => {
    initAnimations()
    window.equal = initEqualizer
    let im = new Inputmask("+7 (999) 999-99-99")
    im.mask(document.querySelectorAll('input[type="tel"]'))


    
})
