export default function initEqualizer(params) {
    let eqLines = document.querySelectorAll(".loader__equalizer div")
    
    if (eqLines.length > 0) {
        eqLines.forEach((line) => {
            setInterval(() => {
                line.style.height = Math.random() * 100 + "px"
            }, 450)
        })
    }
}
